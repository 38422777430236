import React, { useEffect, useState, useContext, useRef } from 'react';
import { Modal, Tabs, List, Collapse, Spin, Space } from 'antd';
import dayjs from 'dayjs';
import { workOrderInfoColumns, workOrderInfoKeyMap, workOrderInfoColumnsName } from '../../utils/keyMap/unusual';
import './work-order-info-modal.css';
import { UserContext } from '../../App.js';
import { getTransferWorkOrderDatasBySearch } from '../../api/request';
import { getKingteamTransferWorkOrderDatasBySearch } from '../../api/erp-utils-api';

const { Panel } = Collapse;

const WorkOrderInfoModal = (props) => {
  const userContext = useContext(UserContext);
  const { isDetailModalOpen, setIsDetailModalOpen, selectedDataRef } = props;
  const [opAndShiftKeys, setOpAndShiftKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchedWorkOrderData = useRef({});

  useEffect(() => {
    const processTransferWorkOrderData = (dataSource) => {
      const items = dataSource.map((item) => {
        const tabKey = `${item.op_order} - ${item.op_code}${item.shift === undefined ? '' : ` - 班別 ${item.shift}`}`;
        return {
          label: tabKey,
          key: tabKey,
          children: (
            <Tabs
              tabPosition="top"
              items={
                Object.keys(item.update_type)
                  .filter((key) => item.update_type[key])
                  .map((key) => {
                    let children = null;
                    switch (key) {
                      case "stock_in":
                        children = generateStockInColumnData(item[key], item['stock_in_total_qty'], key);
                        break;
                      case "get":
                        children = generateGetAndPutColumnData(item[key], key);
                        break;
                      case "put":
                        children = generateGetAndPutColumnData(item[key], key);
                        break;
                      default:
                        children = workOrderInfoColumns[key].map((colName) => generateColumnData(colName, item[key], key));
                        break;
                    }

                    return {
                      label: workOrderInfoColumnsName[key],
                      key: key,
                      children: (
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                          {children}
                        </Space>
                      ),
                      style: { height: 'calc(75vh - 48px - 62px)', overflow: 'scroll' }
                    };
                  })
              }
            />
          ),
        };
      });
      setOpAndShiftKeys(items);
    };

    if (isDetailModalOpen) {
      if (fetchedWorkOrderData.current.hasOwnProperty(selectedDataRef.current) === true) {
        processTransferWorkOrderData(fetchedWorkOrderData.current[selectedDataRef.current]);
      } else {
        // 拿所有需要回填的資訊
        setLoading(true);
        if (userContext.tenantId === 'kingteam-ekgtj') {
          getKingteamTransferWorkOrderDatasBySearch(selectedDataRef.current).then((workOrderInfoData) => {
            fetchedWorkOrderData.current[selectedDataRef.current] = workOrderInfoData;
            processTransferWorkOrderData(workOrderInfoData);
            setLoading(false);
          });
        } else {
          getTransferWorkOrderDatasBySearch({ tenantId: userContext.tenantId, type: 'search', workOrderId: selectedDataRef.current }).then((workOrderInfoData) => {
            fetchedWorkOrderData.current[selectedDataRef.current] = workOrderInfoData;
            processTransferWorkOrderData(workOrderInfoData);
            setLoading(false);
          });
        }
      }
    }
  }, [isDetailModalOpen, selectedDataRef, userContext.tenantId]);


  const generateStockInColumnData = (stockInData, totalStockInQty, key) => {
    let listData = (
      <List
        bordered={true}
        header={null}
        footer={null}
        size="small"
        dataSource={stockInData}
        renderItem={(item, index) => (
          <List.Item>
            <div style={{ disply: 'flex' }}>
              <div>
                {`第 ${index + 1} 張單`}
                <div><strong>{workOrderInfoKeyMap[key]['stock_in_qty']}： </strong>
                  {item.qty + item.scrap_history_qty}
                </div>
                <div><strong>{workOrderInfoKeyMap[key]['scrap_history_qty']}： </strong>
                  {item.scrap_history_qty}
                </div>
                <div><strong>{workOrderInfoKeyMap[key]['serial_number']}： </strong>
                  {item.serial_number}
                </div>
              </div>
            </div>
          </List.Item >
        )}
      />
    );

    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <div>
          <strong>{workOrderInfoKeyMap[key]["stock_in_total_qty"]}： </strong>
          {totalStockInQty}
        </div>
        <div> {listData} </div>
      </div>
    );
  };

  const generateGetAndPutColumnData = (getData, key) => {
    return (
      <Collapse defaultActiveKey={getData.details.map((detail, index) => { return index; })}>
        {getData.details.map((detail, index) => (
          <Panel key={index} header={`${workOrderInfoKeyMap[key]['station']}： ${detail.station} `}>
            {/* 領料清單 */}
            <List
              bordered
              dataSource={detail.product_serial_number_and_qty}
              renderItem={(prod, detailIndex) => (
                <List.Item>
                  <div style={{ disply: 'flex' }}>
                    <div>
                      {`序號 ${detailIndex + 1} `}
                      <div><strong>{workOrderInfoKeyMap[key]['work_order_number_1']}： </strong>
                        {getData.work_order_number_1}
                      </div>
                      <div><strong>{workOrderInfoKeyMap[key]['work_order_number_2']}： </strong>
                        {getData.work_order_number_2}
                      </div>
                      <div><strong>{workOrderInfoKeyMap[key]['product_number']}： </strong>
                        {prod.product_number}
                      </div>
                      <div><strong>{workOrderInfoKeyMap[key]['qty']}： </strong>
                        {prod.qty}
                      </div>
                      <div><strong>{workOrderInfoKeyMap[key]['serial_number']}： </strong>
                        {prod.serial_number}
                      </div>
                      <div><strong>{workOrderInfoKeyMap[key]['warehouse']}： </strong>
                        {prod.warehouse}
                      </div>
                      {prod.is_substitute === true
                        ?
                        <div><strong>{workOrderInfoKeyMap[key]['original_product']}： </strong>
                          {prod.original_product}
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </Panel>
        ))}
      </Collapse>
    )
  };

  const generateColumnData = (colName, itemDataByType, key) => {
    let content = null;
    switch (colName) {
      case "qty":
        content = itemDataByType['good'] + itemDataByType['defect'];
        break;
      case "start_time":
        content = dayjs(itemDataByType[colName]).format('YYYY-MM-DD HH:mm:ss').toString();
        break;
      case "end_time":
        content = dayjs(itemDataByType[colName]).format('YYYY-MM-DD HH:mm:ss').toString();
        break;
      case "defect_reason":
        content = itemDataByType[colName] && itemDataByType[colName].length > 0
          ?
          <List
            style={{ marginTop: '8px' }}
            bordered={true}
            header={null}
            footer={null}
            size="small"
            dataSource={itemDataByType[colName]}
            renderItem={(defectItem, index) => (
              <List.Item>
                <div style={{ disply: 'flex' }}>
                  <div>
                    {`第 ${index + 1} 筆`}
                    <div><strong>{workOrderInfoKeyMap[key]['type']}： </strong>
                      {workOrderInfoKeyMap[key]['scrap_history_qty']}
                    </div>
                    <div><strong>{workOrderInfoKeyMap[key]['qty']}： </strong>
                      {defectItem['qty']}
                    </div>
                    <div><strong>{workOrderInfoKeyMap[key]['category']}： </strong>
                      {defectItem['category']}
                    </div>
                    <div><strong>{workOrderInfoKeyMap[key]['reason']}： </strong>
                      {defectItem['reason']}
                    </div>
                  </div>
                </div >
              </List.Item >
            )}
          />
          :
          workOrderInfoKeyMap['noValue']
        break;
      case "abnormal_time":
        content = itemDataByType[colName] > 0
          ?
          <div style={{ disply: 'flex', marginTop: '8px', border: '1px solid #d9d9d9', borderRadius: '2px', padding: '8px 16px' }}>
            <div><strong>{workOrderInfoKeyMap[key]['reason']}： </strong>
              {workOrderInfoKeyMap[key]['abnormal_reason']}
            </div>
            <div><strong>{workOrderInfoKeyMap[key]['time']}： </strong>
              {Math.round(itemDataByType[colName] * 100) / 100}
            </div>
          </div>
          :
          workOrderInfoKeyMap['noValue']
        break;
      case "exclusive_time":
        content = itemDataByType[colName] > 0
          ?
          <div style={{ disply: 'flex', marginTop: '8px', border: '1px solid #d9d9d9', borderRadius: '2px', padding: '8px 16px' }}>
            <div><strong>{workOrderInfoKeyMap[key]['reason']}： </strong>
              {workOrderInfoKeyMap[key]['exclusive_reason']}
            </div>
            <div><strong>{workOrderInfoKeyMap[key]['time']}： </strong>
              {Math.round(itemDataByType[colName] * 100) / 100}
            </div>
          </div>
          :
          workOrderInfoKeyMap['noValue']
        break;
      default:
        if (typeof itemDataByType[colName] === 'number') {
          itemDataByType[colName] = Math.round(itemDataByType[colName] * 100) / 100;
        }
        content = itemDataByType[colName] === undefined ? workOrderInfoKeyMap['noValue'] : itemDataByType[colName];
        break;
    }

    return (
      <div key={colName}>
        <strong>{workOrderInfoKeyMap[key][colName]}： </strong>
        {content}
      </div>
    );
  };

  const handleOk = () => {
    setIsDetailModalOpen(false);
  };

  const handleCancel = () => {
    setIsDetailModalOpen(false);
  };

  return (
    isDetailModalOpen
      ?
      <Modal
        title={`工單號碼： ${selectedDataRef.current} `}
        open={isDetailModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="85vw"
        bodyStyle={{ height: '75vh' }}
        centered={true}
        footer={null}
      >
        {loading
          ?
          <Spin />
          :
          <Tabs
            tabPosition="left"
            items={opAndShiftKeys}
            style={{ height: '100%' }}
          />
        }
      </Modal>
      :
      null
  );
};
export default WorkOrderInfoModal;