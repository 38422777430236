import React from 'react';
import { Tabs } from 'antd';
import Unusual from './unusual';

const UnusualList = () => {
  return (
    <Tabs
      tabBarStyle={{ marginBottom: '24px' }}
      items={[
        { key: 'work-order-job', label: '失敗的工單', children: <Unusual /> },
      ]}
    />
  );
};

export default UnusualList;
