export const unusualKeyMap = {
  search: '查詢',
  workOrderId: '工單號碼',
  recordTime: '紀錄時間',
  resolveTime: '解決時間',
  X: '無需同步',
  cannotSyncToCus: '託外無法回填ERP',
  workOrderUpdateTime: '工單更新時間',
  action: '動作',
  check: '查看'
}

export const workOrderInfoColumns = {
  'in': ['station_name', 'device_name', 'qty', 'start_time'],
  'out': ['station_name', 'device_name', 'qty', 'worker', 'act_worker_work_time', 'act_machine_work_time', 'end_time', 'defect_reason', 'abnormal_time', 'exclusive_time'],
  'stock_in': ['stock_in_total_qty', 'stock_in_qty', 'scrap_history_qty', 'serial_number'],
  'get': [],
  'put': []
}

export const workOrderInfoColumnsName = {
  'in': '進站',
  'out': '出站',
  'stock_in': '入庫',
  'get': '領料',
  'put': '退料'
}

export const workOrderInfoKeyMap = {
  noValue: '無資料',
  in: {
    station_name: '工作站',
    device_name: '機台',
    qty: '數量',
    start_time: '變更時間'
  },
  out: {
    station_name: '工作站',
    device_name: '機台',
    qty: '數量',
    worker: '人數',
    act_worker_work_time: '人時',
    act_machine_work_time: '機時',
    end_time: '日期時間',
    defect_reason: '例外數量',
    abnormal_time: '例外機時（故障）',
    exclusive_time: '例外機時（暫停）',
    type: '類型',
    category: '群組',
    reason: '原因',
    abnormal_reason: '機械異常',
    exclusive_reason: '暫停生產',
    time: '時間（分）'
  },
  stock_in: {
    stock_in_total_qty: '移轉數量（表頭）',
    stock_in_qty: '移轉數量（表身）',
    scrap_history_qty: '報廢數量',
    serial_number: '批號'
  },
  get: {
    type: '領料單別',
    // date'領料日期',
    station: '生產線別',
    work_order_number_1: '製令單別',
    work_order_number_2: '製令單號',
    product_number: '材料品號',
    qty: '領料數量',
    serial_number: '批號',
    warehouse: '庫別',
    original_product: '被取替代品號',
  },
  put: {
    type: '退料單別',
    // date':退料日期',
    station: '生產線別',
    work_order_number_1: '製令單別',
    work_order_number_2: '製令單號',
    product_number: '材料品號',
    qty: '退料數量',
    serial_number: '批號',
    warehouse: '庫別',
    original_product: '被取替代品號',
  }

}